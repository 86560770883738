/*
 * @Description: 
 * @version: 
 * @Author: Hinsane
 * @Date: 2022-05-27 20:37:28
 * @LastEditors: Hinsane
 * @LastEditTime: 2022-05-28 20:52:23
 */
import { Icon } from 'ant-design-vue';
const MyIcon = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_3329638_9tgq08uer1.js', // 在 iconfont.cn 上生成
});
export {
    MyIcon
}